import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const blogUrl = "https://selbysellssd.com/reasons-to-live-in-san-diego-university-heights";

const BlogPost = () => {
  return (
    <LayoutWrapper
      title="10 Reasons to Live in San Diego’s University Heights in 2023"
      desc="Discover 10 reasons to live in San Diego’s University Heights, from beautiful architecture to a vibrant community & fantastic amenities. Read on to learn more!"
      headerLogo="../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              10 Reasons Why You Should Live in San Diego’s University Heights in 2023
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby Team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  April 25, 2023
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_10 Reasons Why You Should Live in San Diego’s University Heights in 2023.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                From its stunning natural surroundings to its thriving cultural scene, University
                Heights offers a charming and vibrant community with something for everyone. Located
                in the heart of San Diego, this community strikes the perfect balance of
                convenience, tranquility, and easy access to all major destinations. Just a stone's
                throw from stunning Balboa Park and with convenient access to I-8 and I-805,
                University Heights makes it easy to explore all that San Diego has to offer.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                In this article, we'll take you on a journey to discover all the awesome things that
                make University Heights one of our favorite communities.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Stunning Architecture
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                University Heights boasts diverse architectural styles, from Craftsman homes to
                ornate Victorians and Mediterranean-style villas. Many of these homes were built in
                the early 20th century and have been carefully preserved, giving you a glimpse into
                San Diego's rich history and architectural heritage.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Walkable Neighborhoods{" "}
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                With a walk score of 86 and a bike score of 76, University Heights is a highly
                walkable and bike-friendly community. So leave your car at home and enjoy the
                tree-lined views from the sidewalk. You’re going to love slowing down and taking in
                the views.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Kid-Friendly
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Excellent schools and a safe, quiet environment also make University Heights ideal
                for families. It's also filled with kid-friendly activities, like playgrounds at
                Trolley Barn Park, the San Diego Zoo, and Belmont Park, a free-admission theme park
                for all ages.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Ample Green Space
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                University Heights residents can also access numerous parks and greenspaces,
                including Balboa Park. Balboa is a 1,200-acre historic urban cultural park with
                gardens, museums, playgrounds, and performing arts stages. Several other parks are
                nearby, including Ward Canyon Neighborhood Park, which offers a playground,
                basketball court, and picnic area.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Lots of Amenities
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                University Heights also offers many amenities, including reliable public
                transportation with 15 MTS bus stops, daycares, playgrounds, and an abundance of
                restaurants and cafes. The neighborhood also boasts several grocery stores,
                including Trader Joe's and Whole Foods Market, making it easy for residents to
                access fresh and healthy food options.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Perfect for Singles & Families Alike
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Thanks to its excellent schools, safe environment, and abundant recreational
                opportunities, University Heights is a great neighborhood for both families and
                singles. Families will appreciate the wide range of educational options for
                children, including high-quality preschools, daycares, and top-rated public schools.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                For singles, University Heights offers a lively cultural scene, with numerous
                restaurants, cafes, and bars, as well as easy access to downtown San Diego and other
                popular destinations.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Awesome Culinary Scene
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                University Heights has a diverse culinary scene with various bars, restaurants, and
                cafés catering to unique tastes. Our local favorites include Pop Pie, Bahn Thai, The
                Nood Bar, The Kebab Shop, Kairoa Brewing Company, and Empanada Kitchen.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Sustainable, Eco-Friendly Neighbors
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Another reason to live in University Heights, San Diego, is the community's
                commitment to sustainability and eco-friendliness. The neighborhood has a strong
                focus on reducing waste and conserving resources, with many eco-conscious businesses
                and initiatives such as farmer's markets, community gardens, and recycling programs.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Local Events and Festivals
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                University Heights is also known for its annual events and festivals, such as the
                University Heights Arts Open, which showcases local artists and musicians, and the
                Taste of University Heights, where residents and visitors can sample dishes from the
                neighborhood's top restaurants.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Strong Sense of Community
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                University Heights also has a strong sense of community. From farmers' markets and
                outdoor concerts to holiday parades and street fairs, there is always something
                going on to bring residents together and foster a strong sense of belonging.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Ready to Look at Homes?
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                When you want to{" "}
                <a href="https://selbysellssd.com/buy-house-san-diego/">buy a home in San Diego</a>,
                connect with a top-rated team of realtors who listen, are readily available, and
                will work tirelessly for you.{" "}
                <ContactSlideoutLink text="Contact The Selby Team today" />!
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
